import { Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { RadioButtonGroupInput, TextInput, useNotify } from "react-admin";

const OrderEditConfirmModal = ({
  chooseComment,
  change,
  fulfillmentServiceFunc,
  trackingUrlFunc,
  trackingIdFunc,
  posBillNumberFunc,
  warehouse,
  setIsUrlValid
}) => {
  const showTrackingUrlField = warehouse === "Others" && change === "dispatched";
  const [urlError, setUrlError] = useState("");
  const notify = useNotify();
  
  useEffect(() => {
    if (showTrackingUrlField) {
      setIsUrlValid(false);
    } else {
      setIsUrlValid(true);
    }
  }, [showTrackingUrlField, setIsUrlValid]);

  const validateUrl = (value) => {
    if (!value) {
      setUrlError('Tracking URL is required');
      setIsUrlValid(false);
      return 'Tracking URL is required';
    }

    // Remove leading/trailing whitespace
    value = value.trim();
    
    // Check for whitespace within the URL
    if (value.includes(' ')) {
      setUrlError('URL cannot contain spaces');
      setIsUrlValid(false);
      return 'URL cannot contain spaces';
    }

    // URL pattern that requires domain extension and proper format
    const urlPattern = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/;
    
    // Add https:// if not present for URL constructor
    const urlToTest = value.startsWith('http') ? value : `https://${value}`;

    try {
      // First check if it matches basic URL pattern
      if (!urlPattern.test(value)) {
        throw new Error('Invalid URL format');
      }

      // Then try to construct URL object for additional validation
      new URL(urlToTest);

      // Make sure it has a valid domain extension
      const domainParts = value.replace(/^https?:\/\//, '').split('.');
      if (domainParts.length < 2 || domainParts[domainParts.length - 1].length < 2) {
        throw new Error('Invalid domain');
      }

      setUrlError("");
      setIsUrlValid(true);
    } catch (error) {
      const errorMsg = 'Please enter a valid URL (e.g., example.com or https://example.com)';
      setUrlError(errorMsg);
      setIsUrlValid(false);
      return errorMsg;
    }
  };

  const handleUrlChange = (e) => {
    const value = e.target.value;
    const validationError = validateUrl(value);
    
    if (!validationError) {
      trackingUrlFunc(value);
    } else {
      notify(validationError, { type: 'error' });
      trackingUrlFunc(""); // Clear the tracking URL if invalid
    }
  };

  return (
    <div>
      <Typography component="legend" fontWeight="400" fontSize="18px">
        Are you sure you want to update the status of the order?
      </Typography>
      <TextInput
        source="comment"
        multiline
        onChange={(e) => chooseComment(e.target.value)}
        label="Add Comment"
        fullWidth
      />
      {change === "dispatch" ? (
        <RadioButtonGroupInput
          source="carrier"
          label="Fulfillment Service"
          onChange={(e) => fulfillmentServiceFunc(e.target.value)}
          defaultValue='dtdc'
          choices={[
            { id: "dtdc", name: "DTDC" },
            { id: "shiprocket", name: "Shiprocket" },
            { id: "manual", name: "Manual" },
            { id: "delhivery", name: "Delhivery" },
          ]}
        />
      ) : null}
      {showTrackingUrlField && (
        <>
          <TextInput
            source="customer.trackingId"
            onChange={(e) => trackingIdFunc(e.target.value)}
            label="Tracking ID"
            fullWidth
            required
          />
          <TextInput
            source="customer.trackingUrl"
            onChange={handleUrlChange}
            label="Tracking URL"
            fullWidth
            required
            error={!!urlError}
            helperText={urlError || "Enter a valid URL (e.g., tracking.example.com or https://tracking.example.com)"}
          />
        </>
      )}

      {change === "billed" && (
        <TextInput
          required
          source="customer.posBillNumber"
          onChange={(e) => posBillNumberFunc(e.target.value)}
          label="POS Bill Number"
          fullWidth
        />
      )}
    </div>
  );
};

export default OrderEditConfirmModal;
