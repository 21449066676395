import * as React from "react";
import {
  TextField,
  SimpleForm,
  Edit,
  useRedirect,
  TopToolbar,
  SelectInput,
  List,
  useGetOne,
  Confirm,
  DateField,
  useNotify,
  TextInput,
} from "react-admin";
import Button from "@mui/material/Button";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {
  Backdrop,
  Box,
  CircularProgress,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useParams } from "react-router-dom";
import "./orderedit.css";
import OrderEditPdfForm from "./OrderEditPdfForm";
import axios from "axios";
import OrderEditConfirmModal from "./OrderEditConfirmModal";
import { useRef, useState } from "react";
import moment from "moment";
import { fontWeight } from "@mui/system";
import { style } from "../inventory/FileReader";
const orderJsonData = [
  {
    order_id: "123",
    current_status: "PICKED UP",
    delivered_to: "Mumbai",
    destination: "Mumbai",
    consignee_name: "Musarrat",
    origin: "PALWAL",
    activity: [
      {
        date: "2021-12-23 14:23:18",
        status: "X-PPOM",
        activity: "In Transit - Shipment picked up",
        location: "Palwal_NewColony_D (Haryana)",
      },
    ],
  },
];

const OrderName = ({ record }) => {
  return <span> {record ? `"${record.name}"` : ""}</span>;
};

const OrderEditActions = ({ basePath, data, resource }) => {
  const redirect = useRedirect();
  return (
    <TopToolbar
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "row",
      }}
    >
      {/* Add your custom actions */}
      {/* <Button color="primary" onClick={() => redirect("/admin/api/orders")}>
        <ArrowBackIcon />
        <span style={{ marginLeft: "20px" }}>Order View</span>
      </Button> */}
    </TopToolbar>
  );
};

export const OrderEdit = () => {
  const componentRef = useRef();
  const id = useParams();
  const [change, setChange] = React.useState(null);
  const { data: order } = useGetOne("api/orders", id);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (order) {
      setChange(change || order?.status);
      // console.log("Order status:", order?.status, change);
    }
  }, [order, change]);

  return (
    <>
      <Edit
        actions={<OrderEditActions />}
        title={<OrderName />}
        successMessage={"Successfully edit Order"}
      >
        <OrderForm
          componentRef={componentRef}
          change={change}
          setChange={setChange}
          order={order}
        />
      </Edit>
    </>
  );
};

const statuses = [
  { id: "pending", name: "Pending" },
  { id: "product_missing", name: "Product missing" },
  { id: "billed", name: "Billed" },
  { id: "dispatch", name: "Ready to Dispatch" },
  { id: "dispatched", name: "Dispatched" },
  { id: "delivered", name: "Delivered" },
  { id: "return", name: "Return" },
  { id: "cancelled", name: "Cancelled" },
];

const statusOptions = (warehouse) => {
  if (warehouse === "Others") {
    return {
      // pending: ["pending", "product_missing", "billed", "cancelled", "closed"],
      pending: [
        { id: "pending", name: "Pending" },
        { id: "product_missing", name: "Product missing" },
        { id: "billed", name: "Billed" },
        { id: "cancelled", name: "Cancelled" },
        { id: "closed", name: "Closed" },
      ],
      // product_missing: ["product_missing", "billed", "cancelled", "closed"],
      product_missing: [
        { id: "product_missing", name: "Product missing" },
        { id: "billed", name: "Billed" },
        { id: "cancelled", name: "Cancelled" },
        { id: "closed", name: "Closed" },
      ],
      // billed: ["billed", "dispatch", "cancelled", "closed"],
      billed: [
        { id: "billed", name: "Billed" },
        { id: "dispatch", name: "Shipping Method" },
        { id: "cancelled", name: "Cancelled" },
        { id: "closed", name: "Closed" },
      ],
      // dispatch: ["dispatch", "dispatched", "cancelled"],
      dispatch: [
        { id: "dispatch", name: "Shipping Method" },
        { id: "dispatched", name: "Dispatched" },
        { id: "cancelled", name: "Cancelled" },
      ],
      // dispatched: ["dispatched", "delivered", "cancelled"],
      dispatched: [
        { id: "dispatched", name: "Dispatched" },
        { id: "delivered", name: "Delivered" },
        { id: "cancelled", name: "Cancelled" },
      ],
      // delivered: ["delivered", "closed"],
      delivered: [
        { id: "delivered", name: "Delivered" },
        { id: "return", name: "Return" },
        { id: "closed", name: "Closed" },
      ],
      // closed: ["closed", "pending"],
      closed: [
        { id: "closed", name: "Closed" },
        { id: "pending", name: "Pending" },
      ],
      cancelled: [
        { id: "cancelled", name: "Cancelled" },
      ],
      return: [
        { id: "return", name: "Return" },
      ],
    };
  } else {
    return {
      // pending: ["pending", "product_missing", "billed", "cancelled", "closed"],
      pending: [
        { id: "pending", name: "Pending" },
        { id: "product_missing", name: "Product missing" },
        { id: "billed", name: "Billed" },
        { id: "cancelled", name: "Cancelled" },
        { id: "closed", name: "Closed" },
      ],
      // product_missing: ["product_missing", "billed", "cancelled", "closed"],
      product_missing: [
        { id: "product_missing", name: "Product missing" },
        { id: "billed", name: "Billed" },
        { id: "cancelled", name: "Cancelled" },
        { id: "closed", name: "Closed" },
      ],
      // billed: ["billed", "dispatched", "cancelled", "closed"],
      billed: [
        { id: "billed", name: "Billed" },
        { id: "dispatched", name: "Dispatched" },
        { id: "cancelled", name: "Cancelled" },
        { id: "closed", name: "Closed" },
      ],
      // dispatched: ["dispatched", "delivered", "cancelled"],
      dispatched: [
        { id: "dispatched", name: "Dispatched" },
        { id: "delivered", name: "Delivered" },
        { id: "cancelled", name: "Cancelled" },
      ],
      // delivered: ["delivered", "closed"],
      delivered: [
        { id: "delivered", name: "Delivered" },
        { id: "return", name: "Return" },
        { id: "closed", name: "Closed" },
      ],
      // closed: ["closed", "pending"],
      closed: [
        { id: "closed", name: "Closed" },
        { id: "pending", name: "Pending" },
      ],
    };
  }
};

const OrderForm = ({ componentRef, change, setChange, order }) => {
  const id = useParams();
  // const [change, setChange] = React.useState();
  const [open, setOpen] = React.useState(false);
  const handleDialogClose = () => {
    setOpen(false);
    window.location.reload();
  };
  const [openSync, setOpenSync] = React.useState(false);
  const handleDialogSyncClose = () => setOpenSync(false);
  const [message, setMessage] = React.useState("");
  const [fulfillmentService, setFulfillmentService] = React.useState("dtdc");
  const [trackingUrl, setTrackingUrl] = React.useState("");
  const [posBillNumber, setPosBillNumber] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const notify = useNotify();
  const [modalOpen, setModalOpen] = React.useState(false);
  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);
  const [commentTextareaValue, setCommentTextareaValue] = React.useState(order?.customer?.warehouseComment || '');
  const [isUrlValid, setIsUrlValid] = React.useState(true);
  const [trackingId, setTrackingId] = React.useState("");

  const formattedAmount = (number) => {
    const parsedAmount = parseFloat(number);
    const formattedValue = parsedAmount.toFixed(2);
    const parts = formattedValue.toString().split(".");
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return `₹${integerPart}.${parts[1]}`;
  };

  const statusApi = async (
    id,
    change,
    message,
    fulfillmentService,
    trackingUrl,
    trackingId,
    posBillNumber
  ) => {
    try {
      let payload;
      if (change === "billed") {
        payload = {
          status: change,
          comment: message,
          posBillNumber: posBillNumber,
        };
      } else if (change === "dispatch") {
        payload = {
          status: change,
          comment: message,
          carrier: fulfillmentService,
        };
      } else if (change === "dispatched") {
        payload = {
          status: change,
          comment: message,
          trackingId: trackingId,
          trackingUrl: trackingUrl,
        };
      } else {
        payload = {
          status: change,
          comment: message,
        };
      }
      if (message === "") {
        delete payload.comment;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/oms/order-update/${id}`,
        payload,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      );
      return response;
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  };

  const handleConfirm = async () => {
    if (change === "billed" && posBillNumber === "") {
      notify("Please enter POS bill number", { type: 'error' });
    } else if (change === "dispatched" && order?.warehouse === "Others" && (!trackingUrl || !trackingId)) {
      notify("Please enter both tracking URL and tracking ID", { type: 'error' });
    } else {
      setIsLoading(true);
      try {
        const response = await statusApi(
          id.id,
          change,
          message,
          fulfillmentService,
          trackingUrl,
          trackingId,
          posBillNumber
        );
        console.log("API Response:", response.data);
        
        setOpen(false);
        window.location.reload();
      } catch (error) {
        console.log(error);
        notify("An error occurred while updating the order", { type: 'error' });
      } finally {
        setIsLoading(false);
      }
    }
  };
  const handleCommentTextareaChange = (event) => {
    setCommentTextareaValue(event.target.value);
  };

  const handleEditComment = () => {
    const commentMetafieldId = order && order?.customer?.commentMetafieldId;
    const order_id =  order && order?.order_id;;
    const comment = commentTextareaValue;

    const payload = {
      commentMetafieldId,
      order_id,
      comment,
    };

    const headers = {
      authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      accept: "application/json",
    }

     axios.post(`${process.env.REACT_APP_BASE_API_URL}/oms/edit-comments`, payload, { headers })
      .then((response) => {
        console.log('API Response:', response.data);
        handleClose();
        window.location.reload();
      })
      .catch((error) => {
        console.error('API Error:', error);
      });
  };

  const filteredOptions = statusOptions(order?.warehouse)[change] || [];
  // console.log(filteredOptions);
  const { accessToken } = JSON.parse(localStorage.getItem("auth"));

  const tableData = JSON.parse(order?.line_items);
  // console.log(order.status);
  // setChange(order.status || change)
  const handleChange = (event) => {
    setOpen(true);
    setChange(event.target.value);
  };

  // sync
  const handleSync = () => {
    setOpenSync(true);
  };

  const fulfillmentServiceFunc = (message) => {
    setFulfillmentService(message);
  };

  const chooseComment = (message) => {
    setMessage(message);
  };
  const trackingUrlFunc = (message) => {
    setTrackingUrl(message);
  };
  const posBillNumberFunc = (message) => {
    setPosBillNumber(message);
  };

  const trackingIdFunc = (message) => {
    setTrackingId(message);
  };

  return (
    <>
      <SimpleForm toolbar={false} padding="20px">
        {/* Order Box */}
        <Box width="100%" display="flex" flexDirection="row">
          <Box
            width="75%"
            display="flex"
            marginTop="20px"
            flexDirection="column"
          >
            <Typography
              component="legend"
              fontWeight="400"
              fontSize="20px"
              display="flex"
            >
              Order & Account Information{" "}
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <span style={{ fontWeight: "400", fontSize: "20px" }}>- #</span>
                <TextField
                  source="order_number"
                  style={{ fontWeight: "400", fontSize: "18px" }}
                />
              </span>
            </Typography>
            <Divider
              sx={{
                width: "70%",
                fontWeight: "bold",
                bgcolor: "background.paper",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            />
            <Box display="flex">
              <Box width="70%">
                <Typography component="legend" marginTop="20px">
                  {" "}
                  <span style={{ fontWeight: "500" }}>Order Id: </span>{" "}
                  <TextField source="order_id" />{" "}
                </Typography>
                <Typography component="legend" marginTop="20px">
                  {" "}
                  <span style={{ fontWeight: "500" }}>
                    Customer Name:{" "}
                  </span>{" "}
                  <TextField source="customer.default_address.name" />{" "}
                </Typography>
                <Typography component="legend" marginTop="20px">
                  {" "}
                  <span style={{ fontWeight: "500" }}>Customer Email: </span>
                  <TextField source="customer.email" />{" "}
                </Typography>
                <Typography component="legend" marginTop="20px">
                  {" "}
                  <span style={{ fontWeight: "500" }}>Order Date: </span>
                  <DateField source="processed_at" />{" "}
                </Typography>
                <Typography component="legend" marginTop="20px">
                  {" "}
                  <span style={{ fontWeight: "500" }}>Order Zone: </span>
                  <TextField source="warehouse" />{" "}
                </Typography>
                <Typography component="legend" marginTop="20px">
                  {" "}
                  <span style={{ fontWeight: "500" }}>Customer phone: </span>
                  <TextField source="customer.phone" />{" "}
                </Typography>
              </Box>
              <Box width="35%">
                <Typography component="legend" marginTop="20px">
                  {" "}
                  <span style={{ fontWeight: "500" }}>
                    POS Bill Number:{" "}
                  </span>{" "}
                  <TextField source="customer.posBillNumber" />{" "}
                </Typography>
                <Typography component="legend" marginTop="20px">
                    <span style={{ fontWeight: "500" }}>Order Created At:</span>{" "}
                    {moment(order?.processed_at).format("DD/MM/YYYY, h:mm:ss A")}
                </Typography>
                <Typography component="legend" marginTop="20px">
                    <span style={{ fontWeight: "500" }}>Order Status:</span>{" "}
                    {order?.status}
                </Typography>
                <Typography component="legend" marginTop="20px" >
                  <span style={{ fontWeight: "500" }}>
                  Payment: <TextField source="gateway" />
                  </span>
                  {/* <Typography component="legend">
                  <span style={{ fontWeight: "500" }}>
                  PaymentID:<TextField source="gateway" />
                  </span>
                  </Typography> */}
                </Typography>
               
                <Typography component="legend" marginTop="20px">
                  <span style={{ fontWeight: "500" }}>
                    Financial Status:{" "}
                  </span>
                  {order?.financial_status ? (
                    <span>Paid</span>
                  ) : (
                    <span>Not Paid</span>
                  )}
                </Typography>

              </Box>
            </Box>
          </Box>

          <Box width="25%" display="flex" justifyContent="flex-end">
            {!change ? (
              <SelectInput
                source="status"
                translateChoice={false}
                onChange={handleChange}
                choices={statuses}
              />
            ) : (
              <SelectInput
                source="status"
                label="Status"
                choices={
                  filteredOptions &&
                  filteredOptions?.map((opt) => ({
                    id: opt.id,
                    name: opt.name,
                  }))
                }
                onChange={handleChange}
              />
            )}
            {isLoading ? (
              <Backdrop open={true} style={{ zIndex: 9999 }}>
                <CircularProgress color="inherit" />
              </Backdrop>
            ) : (
              <Confirm
                isOpen={open}
                title="Order Status Update"
                content={
                  <>
                    <OrderEditConfirmModal
                      chooseComment={chooseComment}
                      change={change}
                      warehouse={order?.warehouse}
                      fulfillmentServiceFunc={fulfillmentServiceFunc}
                      trackingUrlFunc={trackingUrlFunc}
                      trackingIdFunc={trackingIdFunc}
                      posBillNumberFunc={posBillNumberFunc}
                      setIsUrlValid={setIsUrlValid}
                    />
                  </>
                }
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
                confirmColor="primary"
                disabled={!isUrlValid}
              />
            )}
          </Box>
          {/* Aside sync */}
          {/* <Box width="10%">
            <Button
              sx={{ marginTop: "9px", marginLeft: "15px" }}
              variant="contained"
              onClick={handleSync}
            >
              Sync
            </Button>
            <Dialog open={openSync} onClose={handleDialogSyncClose}>
              <DialogTitle>Sync Data</DialogTitle>
              <DialogContent>
                {orderJsonData?.map((item) => (
                  <>
                    <Typography>
                      <span style={{ fontWeight: "500" }}>order_id: </span>
                      {item?.order_id}
                    </Typography>
                    <Typography>
                      <span style={{ fontWeight: "500" }}>
                        Current Status:{" "}
                      </span>
                      {item?.current_status}
                    </Typography>
                    <Typography>
                      <span style={{ fontWeight: "500" }}>Delivered to: </span>
                      {item.delivered_to}
                    </Typography>
                    <Typography>
                      <span style={{ fontWeight: "500" }}>Destination: </span>
                      {item.destination}
                    </Typography>
                    <Typography>
                      <span style={{ fontWeight: "500" }}>
                        Consignee name:{" "}
                      </span>
                      {item.consignee_name}
                    </Typography>
                    <Typography>
                      <span style={{ fontWeight: "500" }}>Origin: </span>
                      {item.origin}
                    </Typography>
                    <table style={{ marginTop: "20px" }}>
                      <tr>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Activity</th>
                        <th>Location</th>
                      </tr>
                      {item.activity.map((data) => (
                        <tr key={data.date}>
                          <td>
                            <Typography>
                              {moment(data.date).utc().format("DD-MM-YYYY")}
                            </Typography>
                          </td>
                          <td>
                            <Typography>{data.status}</Typography>
                          </td>
                          <td>
                            <Typography>{data.activity}</Typography>
                          </td>
                          <td>
                            <Typography>{data.location}</Typography>
                          </td>
                        </tr>
                      ))}
                    </table>
                  </>
                ))}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialogSyncClose}>Close</Button>
              </DialogActions>
            </Dialog>
          </Box> */}
        </Box>

        {/* Main Address Info Box */}
        <Box
          width="100%"
          display="flex"
          marginTop="20px"
          flexDirection="column"
        >
          <Typography component="legend" fontWeight="400" fontSize="20px">
            Address Information:
          </Typography>
          <Divider
            sx={{
              width: "50%",
              fontWeight: "bold",
              bgcolor: "background.paper",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          />
          {/* Inside Row Box */}
          <Box width="100%" display="flex" flexDirection="row">
            {/* 1st Row */}
            <Box width="100%" display="flex" flexDirection="column">
              <Typography
                component="legend"
                marginTop="20px"
                fontWeight="500"
                marginBottom="10px"
              >
                Billing Address:
              </Typography>
              {/* Start Billing Address */}
                <Typography>
                  <span style={{ fontWeight: "500" }}>Name: </span>
                  {order?.customer.billing_address?.name ? (
                    <TextField source="customer.billing_address.name" fontWeight="400" />
                  ) : (
                    <TextField
                      marginBottom="4px"
                      source="customer.default_address.name"
                      fontWeight="400"
                    />
                  )}
                </Typography>
              
                <Typography>
                    <span style={{fontWeight:'500'}}>Address1: </span>
                    { order?.customer.billing_address?.address1 ?
                      <TextField
                      marginBottom="4px"
                      source="customer.billing_address.address1"
                      fontWeight="400"
                      /> : <TextField
                      marginBottom="4px"
                      source="customer.default_address.address1"
                      fontWeight="400"
                    /> 
                   }
                </Typography>
                <Typography>
                    <span style={{fontWeight:'500'}}>Address2: </span>
                    { order?.customer.billing_address?.address2 ?
                      <TextField
                      marginBottom="4px"
                      source="customer.billing_address.address2"
                      fontWeight="400"
                      /> : 
                    
                       <TextField
                          marginBottom="4px"
                          source="customer.default_address.address2"
                          fontWeight="400"
                          />
                    } 
                      <>     
                      <span><span> </span>
                        { order?.customer.billing_address?.city ?
                            <TextField
                                marginBottom="4px"
                                source="customer.billing_address.city"
                                fontWeight="400"
                            />
                        
                          :
                            <TextField
                                marginBottom="4px"
                                source="customer.default_address.city"
                                fontWeight="400"
                            />
                        }
                        <span>, </span>
                        { order?.customer.billing_address?.province ?
                          <TextField
                              source="customer.billing_address.province"
                              fontWeight="400"
                          /> :
                          <TextField
                              source="customer.default_address.province"
                              fontWeight="400"
                          />
                          }
                        <span> </span>
                        { order?.customer.billing_address?.zip ?
                            <TextField
                                source="customer.billing_address.zip"
                                fontWeight="400"
                            /> :
                            <TextField
                                source="customer.default_address.zip"
                                fontWeight="400"
                            />
                        }
                      </span>
                    </>
                </Typography>
                { order?.customer.billing_address?.country ? (
                    <TextField source="customer.billing_address.country" fontWeight="400" />
                ) : (
                    <TextField
                        marginBottom="4px"
                        source="customer.default_address.country"
                        fontWeight="400"
                    />
                )} 
              <Typography>
                <span>Tel: </span>
                { order?.customer.billing_address?.phone ? (
                    <TextField source="customer.billing_address.phone" fontWeight="400" />
                ) : (
                    <TextField
                        marginBottom="4px"
                        source="customer.default_address.phone"
                        fontWeight="400"
                    />
                )}
            </Typography>
              {/* End Billing Address */}
              <Typography>
                Customer phone:{" "}
                <TextField
                  source="customer.phone"
                  fontWeight="400"
                />
              </Typography>
            </Box>
            {/* 2nd Row */}
            <Box width="100%" display="flex" flexDirection="column">
              <Typography
                component="legend"
                marginTop="20px"
                fontWeight="500"
                marginBottom="10px"
              >
                Shipping Address:
              </Typography>
              <Typography>
                <span style={{fontWeight:'500'}}>Name: </span>
                <TextField
                  marginBottom="4px"
                  source="shipping_address.name"
                  fontWeight="400"
                />
              </Typography>
              <Typography>
                <span style={{fontWeight:'500'}}>Address1: </span>
                <TextField
                  marginBottom="4px"
                  source="shipping_address.address1"
                  fontWeight="400"
                />
              </Typography>
             
              <Typography>
                <span style={{fontWeight:'500'}}>Address2: </span>
                <TextField
                  marginBottom="4px"
                  source="shipping_address.address2"
                  fontWeight="400"
                />
                <span>
                {" "}
                <TextField
                  marginBottom="4px"
                  source="shipping_address.city"
                  fontWeight="400"
                />
                <span>, </span>
                <TextField
                  source="shipping_address.province"
                  fontWeight="400"
                />
                <span> </span>
                <TextField source="shipping_address.zip" fontWeight="400" />
              </span>
              </Typography>
              
              <TextField
                marginBottom="4px"
                source="shipping_address.country"
                fontWeight="400"
              />
              <Typography>
                Tel:{" "}
                <TextField source="shipping_address.phone" fontWeight="400" />
              </Typography>
              <Typography>
                Customer phone:{" "}
                <TextField
                  source="customer.phone"
                  fontWeight="400"
                />
              </Typography>
            </Box>
          </Box>
          {/* Payment & Shipping Method */}

          {/* Payment & Shipping Method */}
          <Box width="100%" marginTop="20px" flexDirection="column">
            <Typography component="legend" fontWeight="400" fontSize="20px">
              Items Ordered
            </Typography>
            <Divider
              sx={{
                width: "50%",
                fontWeight: "bold",
                bgcolor: "background.paper",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            />
            {/* Order data */}
            <List
              style={{ marginTop: "-50px" }}
              exporter={false}
              hasCreate={false}
              pagination={false}
            >
              <table>
                <tr>
                  <th
                    style={{
                      border: "1px solid #E5E4E2",
                      fontSize: "14px",
                      backgroundColor: "white",
                    }}
                  >
                    Product
                  </th>
                  <th
                    style={{
                      border: "1px solid #E5E4E2",
                      fontSize: "14px",
                      backgroundColor: "white",
                    }}
                  >
                    SKU
                  </th>
                  <th
                    style={{
                      border: "1px solid #E5E4E2",
                      fontSize: "14px",
                      backgroundColor: "white",
                    }}
                  >
                    Original Price
                  </th>
                  <th
                    style={{
                      border: "1px solid #E5E4E2",
                      fontSize: "14px",
                      backgroundColor: "white",
                    }}
                  >
                    Qty
                  </th>
                  <th
                    style={{
                      border: "1px solid #E5E4E2",
                      fontSize: "14px",
                      backgroundColor: "white",
                    }}
                  >
                   Discount
                  </th>
                  <th
                    style={{
                      border: "1px solid #E5E4E2",
                      fontSize: "14px",
                      backgroundColor: "white",
                    }}
                  >
                    Subtotal
                  </th>
                </tr>
                {tableData.map((data) => (
                  <tr style={{ border: "1px solid #E5E4E2" }} key={data.id}>
                    <td style={{ fontWeight: "500", fontSize: "14px" }}>
                      {data.name}
                    </td>
                    <td style={{ fontWeight: "500", fontSize: "14px" }}>
                      {data.sku}
                    </td>
                    <td style={{ fontWeight: "500", fontSize: "14px" }}>
                      ₹{data.price}
                    </td>
                    <td style={{ fontWeight: "500", fontSize: "14px" }}>
                      {data.quantity}
                    </td>
                    <td style={{ fontWeight: "500", fontSize: "14px" }}>
                      ₹{data.total_discount}
                    </td>
                    <td style={{ fontWeight: "500", fontSize: "14px" }}>
                      ₹{data.quantity * data.price}
                    </td>
                  </tr>
                ))}
              </table>
            </List>

            <div style={{ display: "flex", marginTop: "50px" }}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "60%",
                  marginRight: "60px",
                }}
              >
                <Typography component="legend" marginTop="20px">
                  {
                    order?.customer?.warehouseComment && 
                    <span style={{ fontWeight: "500" }}>Comment: <Button onClick={() => {
                      handleOpen();
                    }}>Edit Comment</Button> </span>
                  }
                 
                <Dialog
                  open={modalOpen}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Edit Comment"}
                  </DialogTitle>
                  <DialogContent>
                  <textarea value={commentTextareaValue} onChange={handleCommentTextareaChange} style={{padding:'5px', fontSize:"16px"}} cols={50} rows={10} />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleEditComment} autoFocus>
                      Save
                    </Button>
                  </DialogActions>
                </Dialog>
                  {order?.customer?.warehouseComment &&
                    order?.customer?.warehouseComment
                      .split("\n")
                      .map((myformatString) => {
                        const dateStringMatch = myformatString.match(/\[(.*?)\]/);
                        let formattedDate = '';
                        if (dateStringMatch) {
                          const dateString = dateStringMatch[1];
                          // formattedDate = moment(dateString).format("DD/MM/YYYY, h:mm:ss A")
                          formattedDate = dateString
                        }
                        return(
                          <li> {myformatString.split(' - ')[0]} {formattedDate} {myformatString.split(' - ')[2]}</li>
                        )
                      })}
                </Typography>

                {order?.warehouse && order?.warehouse === "Others" && (
                  <>
                    <Typography component="legend" marginTop="20px">
                      {" "}
                      <span style={{ fontWeight: "500" }}>Carrier: </span>{" "}
                      <TextField source="customer.fulfillmentService" />{" "}
                    </Typography>
                    <Typography component="legend" marginTop="20px">
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        Fulfillment Order ID:{" "}
                      </span>{" "}
                      <TextField source="customer.fulfillmentOrderId" />{" "}
                    </Typography>
                    <Typography component="legend" marginTop="20px">
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        Tracking ID:{" "}
                      </span>{" "}
                      <TextField source="customer.trackingId" />{" "}
                    </Typography>
                    <Typography component="legend" marginTop="20px">
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        Tracking URL:{" "}
                      </span>{" "}
                      <TextField source="customer.trackingUrl" />{" "}
                    </Typography>
                  </>
                )}
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "40%",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "400",
                    marginBottom: "10px",
                    fontSize: "18px",
                  }}
                >
                  Order Totals
                </Typography>
                <Divider style={{ marginBottom: "10px" }} />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography>Subtotal:</Typography>
                  <Typography>
                    {formattedAmount(parseInt(order?.current_subtotal_price) + parseInt(order?.discount))}
                  </Typography>
                </div>
               {
                order?.discount > 0 && 
                  <div
                    style={{ display: "flex", justifyContent: "space-between",  marginTop: "10px", }}
                  >
                  <Typography>Coupon Discount:</Typography>
                  <Typography>{formattedAmount(order?.discount)}</Typography>
              </div>
               }
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <Typography>Shipping & Handling:</Typography>
                  <Typography>{formattedAmount(order?.shipment)}</Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <Typography style={{ fontWeight: "600" }}>
                    Grand Total:
                  </Typography>
                  <Typography style={{ fontWeight: "600" }}>
                    {formattedAmount(order?.total_price)}
                  </Typography>
                </div>

                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <Typography style={{ fontWeight: "600" }}>
                    Total Paid:
                  </Typography>
                  <Typography style={{ fontWeight: "600" }}>₹0.00</Typography>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <Typography style={{ fontWeight: "600" }}>
                    Total Due:
                  </Typography>
                  <Typography style={{ fontWeight: "600" }}>₹0.00</Typography>
                </div> */}
              </Box>
            </div>
          </Box>
        </Box>
      </SimpleForm>
      <OrderEditPdfForm
        componentRef={componentRef}
        order={order}
        tableData={tableData}
      />
    </>
  );
};

