import * as React from "react";
import { SimpleForm, TextInput, Create, SelectInput, PasswordInput } from "react-admin";

const roles = {
  admin: "admin",
  tardeomanager: "tardeo_manager",
  goregaonmanager: "goregaon_manager",
  contentmanager: "content_manager",
  inventorymanager: "inventory_manager",
  navimumbaimanager: "navimumbai_manager",
  thanemanager: "thane_manager",   
  othersmanger: "others_manager",   
};

const transform = (data) => ({
  ...data,
  roles: [data.roles], 
});

export const UserCreate = (props) => (
  <Create 
    title="Create new user" 
    warnWhenUnsavedChanges 
    {...props}
    transform={transform}
  >
    <SimpleForm>
      <SelectInput
        label="Select Role"
        source="roles"
        fullWidth
        required
        choices={[
          { id: roles.admin, name: "Admin" },
          { id: roles.tardeomanager, name: "Tardeomanager" },
          { id: roles.goregaonmanager, name: "Goregaonmanager" },
          { id: roles.contentmanager, name: "Contentmanager" },
          { id: roles.inventorymanager, name: "Inventorymanager" },
          { id: roles.navimumbaimanager, name: "Navimumbaimanager" },
          { id: roles.thanemanager, name: "Thanemanager" },
          { id: roles.othersmanger, name: "Othersmanager" },
        ]}
      />

      <TextInput fullWidth source="username" required />
      <TextInput fullWidth source="firstName" required />
      <TextInput fullWidth source="lastName" required/>
      <PasswordInput fullWidth source="password" required />
    </SimpleForm>
  </Create>
);